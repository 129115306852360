export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: {
    hotBannerImage: () => import('~/components/Molecules/BannerImage/BannerImage.vue'),
  },
}
